<template  lang="pug">
    v-container()
        v-row()
            v-col( cols="8" offset="2")
                v-card(outlined)
                    v-card-text
                        ValidationObserver(ref='observer')
                            form(action="#" @submit.prevent="handleForm")
                                v-container(class="")
                                    v-row()
                                        v-col(cols="12")
                                            h2( class="text-h6 text-center font-weight-bold blue-500--text mb-5") Buzon de mensajes
                                            p(class="text-center") Aquí puedes escribir cualquier sugerencia, error o problema con la pagina.
                                        v-col(sm="12" v-if="alertSuccess || alertError")
                                            v-alert( type="success" v-model="alertSuccess" dismissible ) Su mensaje ha sido enviado con éxito.
                                            v-alert( type="error" v-model="alertError" dismissible ) Error al enviar el mensaje.
                                        v-col(sm="12")
                                            validation-provider(v-slot="{ errors }" name="Asunto" rules="required")
                                                p(class="mb-0 text-caption") Asunto
                                                v-text-field(
                                                    :error-messages="errors"
                                                    required
                                                    label='Escribe un asunto'
                                                    type="text"
                                                    v-model='form.subject'
                                                    clearable )
                                        v-col(sm="12")
                                            validation-provider(v-slot="{ errors }" name="Mensaje" rules="required")
                                                p(class="mb-0 text-caption") Mensaje
                                                v-textarea(
                                                    :error-messages="errors"
                                                    required
                                                    label='Escribe un mensaje'
                                                    type="text"
                                                    v-model='form.message'
                                                    clearable )
                                        v-col(sm="12")
                                            v-btn(color="primary" :loading="loading" :disabled="loading" type="submit" class="mr-3" small) Enviar
                                            v-btn(color="default" small @click="$router.go(-1)" v-if="showBtnBack") Regresar
                                        
                    
                        
</template>
<script>
import CommentsService from '@/services/CommentsService'
const commentsService = new CommentsService();
export default {
    name: 'BuzonMensajes',
    data() {
        return {
            form: {
                subject: '',
                message: ''
            },
            loading: false,
            alertSuccess: false,
            alertError: false,
        }
    },
    computed: {
        showBtnBack() {
            return window.history.length > 2
        }
    },
    methods: {
        submit() {
            this.loading = true
            commentsService
                .create(this.form)
                .then(response => {
                    // reset form
                    this.form.subject = '';
                    this.form.message = '';
                    this.$nextTick(() => {
                        this.$refs.observer.reset();
                    });
                    this.alertSuccess = true
                    setTimeout(() => {
                        this.alertSuccess = false
                    }, 10000)
                })
                .catch(() => {
                    this.alertError = true
                    setTimeout(() => {
                        this.alertError = false
                    }, 10000)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleForm() {
            this.$refs.observer
                .validate()
                .then( async res=> {
                    if(res){
                        this.submit()
                    } else {
                    }
                });
        },
    },
}
</script>
